import styled from '@emotion/styled';

const MFRInput = styled('input')({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  width: '100%',
});

export default MFRInput;
