import styled from '@emotion/styled';

const FavoritesRadioWrapper = styled('div')({
  bottom: '-0.8rem',
  height: '4.1rem',
  left: '1.1rem',
  opacity: '0.4',
  overflow: 'hidden',
  pointerEvents: 'none',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 'calc(100% - 2rem)',
});

export default FavoritesRadioWrapper;
